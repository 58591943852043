import _ from "lodash"
import matchSorter from "match-sorter"
import React, { useCallback, useEffect, useState } from "react"
import { connect } from "react-redux"
import {
  Button,
  Container,
  Dimmer,
  Dropdown,
  Form,
  Grid,
  Header,
  Label,
  Loader,
  Modal,
  Popup,
  Radio,
  Search,
  Segment,
} from "semantic-ui-react"
import Can from "../../../abilities/Can"
import PaginatedReusableTable from "../../../components/shared/PaginatedReusableTable"
import { history } from "../../../routers/AppRouters"
import {
  resetDailySearchBudget,
  startGetDailyBudget,
  startGetDailyBudgetsById,
} from "../../../store/actions/core/daily-project/daily-project"
import { amISuperAdmin } from "../../../utils/functionUtils"

const searchOptions = [
  { value: "project", label: "Project code & description" },
  // { value: "project_type", label: "Project Type" }, // Bisogna assegnare il permesso di accesso ad offer-request per Docflow, prima introdurre il filtro di ricerca per type
  { value: "billing_client", label: "Billing client" },
  { value: "project_manager", label: "Project manager" },
]

const ProjectStatuses = [
  { value: "all", text: "All" },
  { value: "active", text: "Active" },
  { value: "inactive", text: "Inactive" },
  { value: "pending", text: "Pending" },
  { value: "rejected", text: "Rejected" },
]

const BudgetCols = [
  {
    Header: "Project Code",
    accessor: "project_code",
    id: "project_code",
    filterAll: true,
  },
  {
    Header: "Description",
    accessor: "description",
    id: "description",
    filterAll: true,
  },
  {
    Header: "Project Type",
    accessor: "project_type",
    id: "project_type",
    filterAll: true,
  },
  {
    Header: "Project Status",
    accessor: "project_status",
    id: "project_status",
    filterAll: true,
  },
  {
    Header: "Start Date",
    accessor: "start_date",
    id: "start_date",
    filterAll: true,
    width: 88,
  },
  {
    Header: "End Date",
    accessor: "end_date",
    id: "end_date",
    filterAll: true,
    width: 88,
  },
  {
    Header: "Billing Client",
    accessor: "billing_client",
    id: "billing_client",
    filterAll: true,
  },
  {
    Header: "Last Confirmation",
    accessor: "last_confirmation",
    id: "last_confirmation",
    filterAll: true,
  },
  {
    Header: "Status",
    accessor: "status",
    id: "status",
    Cell: (row) => {
      switch (row.value) {
        case "created":
          return "Created"
        case "cutoff":
          return "Cutoff"
        case "actual":
          return "Actual"
        case "pending":
          return "Pending"
        case "actual pending":
          return "Actual Pending"
        case "change requested":
          return "Change Requested"
        case "cr pending":
          return "CR Pending"
        case "confirmed":
          return "Confirmed"
        case "saved":
          return "Saved"
        case "Not Done":
          return "Not Done"
        case "Done":
          return "Done"

        default:
          return ""
      }
    },
    filterMethod: (filter, row) => {
      if (filter.value === "all") {
        return true
      } else {
        console.log("FILTER:", row.status, filter.value)
        return row.status === filter.value
      }
    },
    Filter: ({ filter, onChange }) => (
      <select
        onChange={(event) => onChange(event.target.value)}
        style={{ width: "100%" }}
        value={filter ? filter.value : "all"}
      >
        <option value="all">Show All</option>
        <option value="done">Done</option>
        <option value="NotFoundContainer">Not Done</option>
      </select>
    ),
  },
  {
    Header: "Gross Margin",
    accessor: "gross_margin",
    id: "gross_margin",
    show: false
  }
]

const DailyProgressContainer = ({
  options,
  getDailyBudget,
  user,
  budgets,
  loading,
  user_groups,
  abilities,
  getBudgetsById,
}) => {
  const totalElements = budgets && budgets.meta ? budgets.meta.total_element : undefined
  const staticSize = 20
  const [state, setState] = useState({
    values: {},
    filters: { start: 0, size: staticSize, order_direction: "", order: "", type: "", query: "" },
    page: 0,
    pageSize: 20,
    selectedRow: {},
    user_groups: [],
    abilities: [],
    searchValue: "",
    searchOption: "project",
    openSearchModal: false,
  })
  const [filteredBudgets, setFilteredBudgets] = useState([])
  const { openSearchModal, searchOption, searchValue, page, pageSize, filters } = state
  const searchOptionLabel = (searchOptions.find((x) => x.value === searchOption) || {}).label || "Project code"
  const matchedOption = searchOptions.find((x) => x.value === searchOption)
  const optionLabel = matchedOption ? matchedOption.label : "Default Label"
  const data = budgets ? budgets.data : []
  const meta = budgets ? budgets.meta : undefined
  let pages = meta ? Math.ceil(totalElements / meta.size) : -1

  const filterMethod = (filter, rows, id) =>
    matchSorter(rows, filter.value, {
      keys: [id],
      threshold: matchSorter.rankings.CONTAINS,
    })

  const BudgetColsRefactored = BudgetCols.map((col) => ({
    ...col,
    filterMethod: (filter, rows) => filterMethod(filter, rows, col.id),
  }))

  const debouncedSearch = useCallback(
    _.debounce((query) => {
      if (!query.length) {
        getDailyBudget({ ...filters })
      } else {
        let params = { ...filters, query, type: searchOption }
        getDailyBudget(params)
      }
      setState((prevState) => ({ ...prevState, page: 0 }))
    }, 500),
    [filters.size, searchOption]
  )

  useEffect(() => {
    if (!searchValue) {
      getDailyBudget(state.filters)
    }
  }, [state.filters.size])
  useEffect(() => {
    let user_groups = {}
    if (user)
      if (user.signInUserSession.idToken.payload) {
        user_groups = JSON.parse(user.signInUserSession.idToken.payload.user_groups)
      }
    setState((prevState) => ({ ...prevState, user_groups: user_groups }))
  }, [])

  const onPageChange = (pageIndex) => {
    setState(prevState => {
      const newFilters = {
        ...prevState.filters,
        start: pageIndex * staticSize,
        size: staticSize,
        type: prevState.searchOption,
        query: prevState.searchValue 
      };
      
      getDailyBudget(newFilters);
      
      return {
        ...prevState,
        page: pageIndex,
        filters: newFilters
      };
    });
  }

  const onSelectRow = (selectedRow) => {
    if (selectedRow.status === "pending") {
      return
    } else {
      history.push({
        pathname: "/daily-progress/budgetsDaily/" + selectedRow.id,
        state: {
          project_code: selectedRow.project_code,
          description: selectedRow.description,
          project_type: selectedRow.project_type
        }
      })
    }
    // getBudgetsById(selectedRow.id)
  }
  const handleSearchDropdown = (event, data) => {
    let project_code = data.options.find((o) => o.value === data.value).code
    setState((prevState) => ({
      ...prevState,
      searchValue: project_code,
    }))

    let params = { ...filters, type: searchOption, query: project_code }
    getDailyBudget(params)
  }

  const handleChange = (e, { value }) => {
    setState((prevState) => ({
      ...prevState,
      searchValue: value,
    }))
    debouncedSearch(value)
  }

  const onCloseSearchModal = () => {
    setState((prevState) => ({ ...prevState, openSearchModal: false }))
  }

  const handleChangeRadio = (e, { value }) => {
    setState((prevState) => ({
      ...prevState,
      searchOption: value,
      searchValue: "",
    }))
    searchValue.length >= 1 && debouncedSearch("")
  }

  useEffect(() => {
    const filteredBudgets = data.reduce((acc, current) => {
      const updatedCurrent = { ...current, status: getStatus(current) }
      const existingItemIndex = acc.findIndex((item) => item.project_code === current.project_code)

      if (existingItemIndex === -1) {
        acc.push(updatedCurrent)
      } else {
        acc[existingItemIndex] = shouldUpdate(existingItemIndex, current, acc) ? updatedCurrent : acc[existingItemIndex]
      }

      return acc
    }, [])
    setFilteredBudgets(filteredBudgets)
  }, [budgets])

  function getStatus(current) {
    const lowerCaseStatus = current.status.toLowerCase();
    if (lowerCaseStatus === "pending" ||
      lowerCaseStatus === "cr pending" ||
      lowerCaseStatus === "actual pending" ||
      lowerCaseStatus === "change requested") {
      return "pending";
    } else if (lowerCaseStatus !== "change requested") {
      return lowerCaseStatus === "saved" ? "Done" : "Not Done";
    }
    return current.status;
  }

  function shouldUpdate(index, current, acc) {
    return (
      (acc[index].status.toLowerCase() === "pending" ||
        current.status.toLowerCase() === "pending") && // ! FIX: non veniva pescato il budget con id maggiore
      current.id > acc[index].id
    )
  }

  // console.log('amISuperAdmin: ', amISuperAdmin(abilities));


  return (
    <Container>
      <Header>Daily Progress</Header>

      <Modal
        size="mini"
        open={openSearchModal}
        closeOnEscape={false}
        closeOnDimmerClick={true}
        onClose={onCloseSearchModal}
        closeIcon
        dimmer="blurring"
      >
        <Modal.Header>Select Search Option</Modal.Header>
        <Modal.Content>
          <Form size="massive">
            {searchOptions.map((field, i) => {
              if (field.value === "project_manager" && !amISuperAdmin(abilities)) {
                return null
              }
              return (
                <Form.Field key={i}>
                  <Radio
                    label={field.label}
                    value={field.value}
                    name="radioGroup"
                    checked={searchOption === field.value}
                    onChange={handleChangeRadio}
                  />
                </Form.Field>
              )
            })}
          </Form>
        </Modal.Content>
        <Modal.Actions>
          <Button onClick={onCloseSearchModal} content="Select" />
        </Modal.Actions>
      </Modal>

      <Dimmer active={loading} inverted>
        <Loader indeterminate inverted content="Loading" size="medium" />
      </Dimmer>
      <Can I="daily-progress:Read" a="daily-progress">
        <Segment>
          <Grid>
            <Grid.Row>
              <Grid.Column width={8}>
                <Label className="label-icon" style={{ marginLeft: "10px", display: "inline-block" }}>
                  Search Budget by {searchOptionLabel}
                </Label>
                <Popup
                  content={"Type budget digits to start searching"}
                  trigger={
                    <Label color="teal" circular>
                      ?
                    </Label>
                  }
                />
                {searchOption !== "project_type" ? (
                  <Search
                    name="budget"
                    input={{ fluid: true }}
                    onSearchChange={handleChange}
                    placeholder={"Search all budgets by " + optionLabel + "..."}
                    showNoResults={false}
                    size="large"
                    minCharacters={3}
                    loading={loading}
                    value={searchValue}
                  />
                ) : (
                  <Dropdown
                    name="dropdown_type"
                    placeholder="Choose a Project Type"
                    fluid
                    selection
                    options={options.project_type}
                    onChange={handleSearchDropdown}
                  />
                )}
                <Button
                  floated="right"
                  basic
                  style={{ margin: "0px 10px 0px 5px" }}
                  content={"Search Option: " + searchOptionLabel.toUpperCase()}
                  onClick={() => setState((prev) => ({ ...prev, openSearchModal: true }))}
                  color="teal"
                  icon="filter"
                />
              </Grid.Column>
              <Grid.Column width={6}>
                <Popup
                  content="Refresh page with the same search parameter"
                  trigger={
                    <Button
                      content="Refresh"
                      floated="right"
                      icon="refresh"
                      onClick={() => handleChange(null, { value: searchValue })}
                    />
                  }
                />
              </Grid.Column>
            </Grid.Row>

            <Grid.Row>
              <Grid.Column>
                <div style={{ marginTop: "50px" }}>
                  <PaginatedReusableTable
                    manual={pages > 1 ? true : false}
                    filterable={false}
                    sortable={pages > 1 ? false : true}
                    loading={loading}
                    columns={BudgetColsRefactored}
                    data={filteredBudgets}
                    onClick={onSelectRow}
                    onPageChange={onPageChange}
                    page={page}
                    pages={pages}
                    pageSize={pageSize}
                  />
                </div>
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </Segment>
      </Can>
    </Container>
  )
}

const mapStateToProps = ({ authReducer, dailyBudgetReducer, projectTypeReducer }) => {
  const { user, user_groups, abilities } = authReducer
  const { loading, budgets } = dailyBudgetReducer
  const project_type = _.toArray(
    _.mapValues(_.filter(projectTypeReducer.projecttypes, { active: 1, corporate: 0 }), (o) => {
      return {
        key: o.id,
        value: o.name,
        text: o.name,
        code: o.code,
      }
    })
  )
  return {
    user,
    user_groups,
    abilities,
    loading,
    budgets,
    options: {
      project_type
    },
  }
}

const mapDispatchToProps = (dispatch) => ({
  getDailyBudget: (params) => dispatch(startGetDailyBudget(params)),
  resetSearch: () => dispatch(resetDailySearchBudget()),
  getBudgetsById: (id) => dispatch(startGetDailyBudgetsById(id)),
})

export default connect(mapStateToProps, mapDispatchToProps)(DailyProgressContainer)
